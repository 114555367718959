<template>
  <div class="readme-article">
    <h1 id="系统搭建"></h1>
    <h2 id="系统使用前配置">系统使用前配置</h2>
    <p>
      系统使用前需要先配置机构的一些基础信息，包括招生、学生、财务、教学、系统等基础配置
    </p>
    <p>
      <img src="@/assets/img/recruit/config0-0.png" />
    </p>
  </div>
</template>

<script>
export default {
  name: "Recruit0-0",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>